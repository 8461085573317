import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Nav2 from '../nav/Nav2';
import Helmet from 'react-helmet';
import { apiRequest, getThemeStaticAsset } from '../../util/util';
import { getUser, updateRequireAuth } from '../../store/user/userActions';
import _ from 'lodash';
import { getSettingOpeningHour } from '../../store/opening/openingActions';
import { getSetting } from '../../store/setting/settingActions';
import '../../assets/css/app.scss';
import { getSeo } from '../../store/seo/seoActions';
import ModalComp from '../util/Modal/ModalComp';
import SessionExpire from '../util/AuthenticationError/sessionExpire';
import { PRE_AUTH_ENDPOINT } from '../../util/constants';
import Nav from '../nav/Nav';

function loadServerData({ opening, setting, user, seo, ...otherProps }) {

  // load seo
  if (!seo.loading && _.isEmpty((seo.data))) {
    otherProps.getSeo();
  }

  // load auth user
  if (_.isEmpty(user)) {
    otherProps.getUser(true);
  }

  // load opening hours
  if (!opening.loading && _.isEmpty((opening.data))) {
    otherProps.getOpeningHours();
  }

  // load settings
  if (!setting.loading && _.isEmpty((setting.data))) {
    otherProps.getSettings();
  }
}

function Header({ ...otherProps }) {

  const [loadAssets, setLoadAssets] = useState(0);

  const pageSeo = useMemo(() => {

    // seo is empty
    if (_.isEmpty(otherProps.seo.data)) return false;

    const path = window.location.pathname;

    const seoRule = otherProps.seo.data[path];
    // if SEO not found for the page
    return (seoRule) ? seoRule : false;

  }, [otherProps.seo.data])

  useEffect(() => {
    setLoadAssets(1);

    // load data from server
    loadServerData(otherProps);
  }, []);

  return (<>
    {
      pageSeo &&
      <Helmet>
        <meta charSet={`${pageSeo.charset}`} />
        <title>{`${pageSeo.title}`}</title>
        <meta name="title" content={`${pageSeo.title}`} />
        <meta name="keywords" content={`${pageSeo.keywords.join(',')}`} />
        <meta name="description" content={`${pageSeo.description}`} />
        <meta name="copyright" content={`${pageSeo.copyright}`} />
        {/*<META NAME="geo.position" CONTENT="latitude; longitude">*/}
        {/*<META NAME="geo.placename" CONTENT="Place Name">*/}
        {/*<META NAME="geo.region" CONTENT="Country Subdivision Code">*/}
      </Helmet>
    }
    {
      loadAssets &&
      <Helmet>

        {/*css*/}
        <link href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap" rel="stylesheet" />

        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway:wght@300;400;500&display=swap" rel="stylesheet" />
        {/* <link href={getThemeStaticAsset('css/style-starter.css')} rel="stylesheet" /> */}


        {/*js*/}
        {/*<script src="/static/theme/js/jquery-3.3.1.min.js"></script>*/}
        {/*<script defer src={getThemeStaticAsset('js/jquery.magnific-popup.min.js')}/>*/}
        {/*<script defer src={getThemeStaticAsset('js/config/magnific-popup.js')}/>*/}

        {/*<script defer src={getThemeStaticAsset('js/owl.carousel.js')} />*/}
        {/*script for carousel1*/}
        {/*<script defer src={getThemeStaticAsset('js/config/owl-carousel.js')} />*/}

        {/*disable body scroll which navbar is in active*/}
        <script defer src={getThemeStaticAsset('js/config/scrolling.js')} />

        <script defer src={getThemeStaticAsset('js/bootstrap.min.js')} />

        <script defer src={getThemeStaticAsset('js/config/footer2.js')} />
        
      </Helmet>
    }

    {
      otherProps.show_header &&
      <Nav />

    }

    {
      otherProps.isRequireAuth &&

      <ModalComp
        open={true}
        hideHeader = {true}
        hideFooter={true}
        closeOnBackdropClick={false}
        primaryBackground={true}
        disableFocusEnforcement={true}
      >

        <SessionExpire />
      </ModalComp>

    }
  </>)
}

const mapStateToProps = state => ({
  show_header: state.layout.show_header,
  user: state.user.data,
  opening: state.opening,
  setting: state.setting,
  seo: state.seo,
  isRequireAuth: state.user.requireAuth,
});

const mapDispatchToProps = dispatch => ({
  getUser: (updateState = false) => dispatch(getUser(updateState)),
  getOpeningHours: () => dispatch(getSettingOpeningHour()),
  getSettings: () => dispatch(getSetting()),
  getSeo: () => dispatch(getSeo()),
  updateRequireAuth: (payload) => dispatch(updateRequireAuth(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
