import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import '../../assets/css/custom.scss';
import ScrollAnim from 'rc-scroll-anim';

function About({ showBtn }) {

  const aboutSetting = useSetting([
    'about_img',
    'about_title',
    'about_description',
    'site_title',
  ]);



  return (<>

    <div className="row serv_sec_info">
      <div className="col-lg-6 banner_bottom_grid help fff">

        <img
          src={`${SETTING_FILE_PATH + aboutSetting.about_img}`}
          className="img-fluid radius-image" height="300"
        />

      </div>

      <div className="col-lg-6 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4 text-center">
        <h2 className="title-big text-center about-title">About {aboutSetting.site_title}</h2>

        {
          showBtn &&
          <p className="about-description">{aboutSetting.about_description?.substring(0, 500)}</p>
        }

        {
          !showBtn &&
          <p className="about-description">{aboutSetting.about_description}</p>

        }

        {showBtn &&
          <Link to="/about" className="btn btn-primary btn-style mt-4" >Read More</Link>
        }
      </div>
    </div>


  </>);
}


export default About;
